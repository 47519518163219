import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import { SearchAlt } from "@styled-icons/boxicons-regular/SearchAlt"

const Header = ({ siteTitle, description }) => (
  <header
    style={{
      background: `#33B5FF`,
      marginBottom: `1.45rem`,
      textDecoration: `none`
    }}
  >
    <div
      style={{
        margin: `0 auto`,
        maxWidth: 960,
        padding: `1.45rem`,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "flex-end"
      }}
    >
      <div>
        <h4 style={{ margin: 0 }}>
          <Link
            to="/"
            className={"white"}
          >
            {siteTitle}
          </Link>
        </h4>
        <div className={"white"}>{description}</div>
      </div>
      <div>
        <Link to={`/s`} className={"white"}>
          <SearchAlt height={40}/>
          Site Search
        </Link>
      </div>

    </div>


  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string
}

Header.defaultProps = {
  siteTitle: ``
}

export default Header
