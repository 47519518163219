import React, { useEffect } from "react"
import Header from "./Header"
import "./layout.css"
// import "../assets/css/main.css"
import { graphql, useStaticQuery } from "gatsby"
import SEO from "./seo"

const Layout = ({ children }) => {

  const data = useStaticQuery(graphql`
      query SiteTitleQuery {
          site {
              siteMetadata {
                  title
                  description
              }
          }
      }
  `)

  useEffect(() => {
    document.title = data?.site.siteMetadata.title
  }, [data])

  const { title, description } = data.site.siteMetadata
  return (
    <>
      <Header siteTitle={title} description={description}/>
      <SEO title={title} description={description}/>
      <div
        style={{
          margin: "0 auto",
          maxWidth: 800
          // padding: `${rhythm(1.5)} ${rhythm(3 / 4)}`
        }}
      >
        <main>{children}</main>
        <footer>
          <small>© {new Date().getFullYear()} <a href="https://classfunc.com"> CLASSFUNC SOFTWARES.,JSC</a></small>
        </footer>
      </div>

    </>
  )
}

export default Layout
